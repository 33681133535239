// @ts-nocheck
import {AxiosResponse} from 'axios';
import {IBadge} from '../../models/homepage/IBadge';
import {IFavoriteProject} from '../../models/homepage/IFavoriteProject';
import {IStatisticsIndicator} from '../../models/homepage/IStatisticsIndicator';

interface IHomePageApi {
  /**
   * получить список бейджей с сервера
   */
  getBadges: () => Promise<AxiosResponse<Array<IBadge>>>

  /**
   * получить список избранных проектов
   */
  getFavoriteProjects: () => Promise<AxiosResponse<Array<IFavoriteProject>>>

  /**
   * получить статистику платформы для отображения на главной
   */
  getStatisticsIndicators: () => Promise<AxiosResponse<Array<IStatisticsIndicator>>>
}

export const homePageApi: IHomePageApi = {
  /**
   * получить список бейджей с сервера
   */
  getBadges(): Promise<AxiosResponse<Array<IBadge>>> {
    /**
     * заглушка пока бэк не готов
     */

    return new Promise<AxiosResponse<Array<IBadge>>>(
      (resolve) => (
        setTimeout(
          () => {
            resolve({
              data: [
                {
                  id: '1',
                  name: 'Александр',
                  surname: 'Макаров',
                  department: 'Московский банк',
                  avatar: 'https://sbidea.ru/attachments/1/resized_for_show_5d/550dc8-b862-4b1d-8073-3dc112da07ed/%D0%BC%D0%B0%D0%BA%D0%B0%D1%80%D0%BE%D0%B2.png',
                  achievement: 'Самый активный участник'
                },
                {
                  id: '2',
                  name: 'Валерий',
                  surname: 'Глазырин',
                  department: 'Юго-Западный банк',
                  avatar: 'https://sbidea.ru/attachments/1/resized_for_show_07/e38ac1-feab-46fb-820b-7153fc14769c/%D0%B3%D0%BB%D0%B0%D0%B7%D1%8B%D1%80%D0%B8%D0%BD.png',
                  achievement: 'Самый активный участник'
                },
                {
                  id: '3',
                  name: 'Валентина',
                  surname: 'Алтухова',
                  female: true,
                  department: 'Сев-Зап банк',
                  avatar: 'https://sbidea.ru/attachments/1/resized_for_show_1e/b3c526-42b6-4aff-8515-d7f076c51905/%D0%B0%D0%BB%D1%82%D1%83%D1%85%D0%BE%D0%B2%D0%B0.PNG',
                  achievement: 'Самый активный участник'
                },
                {
                  id: '4',
                  name: 'Юрий',
                  surname: 'Аредаков',
                  department: 'Поволжский банк',
                  avatar: 'https://sbidea.ru/attachments/1/resized_for_show_26/cfa815-9909-4905-a77c-6061485dd977/%D0%B0%D1%80%D0%B5%D0%B4%D0%B0%D0%BA%D0%BE%D0%B2.png',
                  achievement: 'Самый активный участник'
                },
                {
                  id: '5',
                  name: 'Юлия',
                  surname: 'Иванова',
                  female: true,
                  department: 'Среднерусский банк',
                  avatar: '',
                  achievement: 'Самый активный участник'
                },
                {
                  id: '6',
                  name: 'Павел',
                  surname: 'Сбитнев',
                  female: true,
                  department: 'Московский банк',
                  avatar: 'https://sbidea.ru/attachments/1/resized_for_show_e2/04f696-e9b7-44c6-9d7c-84d0442c07ef/%D1%81%D0%B1%D0%B8%D1%82%D0%BD%D0%B5%D0%B2.png',
                  achievement: 'Самый активный участник'
                },
                {
                  id: '7',
                  name: 'Галина',
                  surname: 'Плотникова',
                  female: true,
                  department: 'Московский банк',
                  avatar: 'https://sbidea.ru/attachments/1/resized_for_show_1a/056a1e-72f3-43a9-b5d4-6f3f44db713f/%D0%BF%D0%BB%D0%BE%D1%82%D0%BD%D0%B8%D0%BA%D0%BE%D0%B2%D0%B0.png',
                  achievement: 'Самый активный участник'
                },
                {
                  id: '8',
                  name: 'Татьяна',
                  surname: 'Глущенко',
                  female: true,
                  department: 'Сибирский банк',
                  avatar: 'https://sbidea.ru/attachments/1/resized_for_show_1c/d584da-9c12-4b08-bea7-7d0690651b5b/%D0%93%D0%BB%D1%83%D1%89%D0%B5%D0%BD%D0%BA%D0%BE.png',
                  achievement: 'Самый активный участник'
                },
                {
                  id: '9',
                  name: 'Елена',
                  surname: 'Попова',
                  female: true,
                  department: 'Центрально-Черноземный банк',
                  avatar: 'https://sbidea.ru/attachments/1/resized_for_show_82/f445c7-09f8-49dd-a696-e11502aa61e6/%D0%BF%D0%BE%D0%BF%D0%BE%D0%B2%D0%B0.png',
                  achievement: 'Самый активный участник'
                },
                {
                  id: '10',
                  name: 'Сергей',
                  surname: 'Черненко',
                  department: 'Сибирский банк',
                  avatar: '',
                  achievement: 'Самый активный участник'
                },
                {
                  id: '11',
                  name: 'Роман',
                  surname: 'Сенько',
                  department: 'Центр корпоративных решений',
                  avatar: '',
                  achievement: 'Самый активный участник'
                }
              ],
              status: 200,
              statusText: 'OK',
              headers: null,
              config: {},
              request: {}
            })
          }
        ,1000)
      )
    )
  },

  /**
   * получить список избранных проектов
   */
  getFavoriteProjects(): Promise<AxiosResponse<Array<IFavoriteProject>>> {
    /**
     * заглушка пока бэк не готов
     */

    return new Promise<AxiosResponse<Array<IFavoriteProject>>>(
      (resolve) => (
        setTimeout(
          () => {
            resolve({
              data: [
                {
                  id: '1',
                  imgUrl: 'https://sbidea.ru/attachments/1/resized_for_show_d4/6d31bc-e064-4c15-a7c1-5bfc18d75544/13.png',
                  title: 'СберИдея',
                  subtitle: 'Подай свою идею по улучшению процессов и продуктов Банка по 31 декабря 2022г',
                  description: '',
                  link: '/legacy/sign-in?target_uri=https://sbidea.ru/improject-436302'
                },
                {
                  id: '2',
                  imgUrl: 'https://sbidea.ru/attachments/1/resized_for_show_71/b8b563-13c1-4695-a57a-a27a3b99264c/244.png',
                  title: 'Экосистема',
                  subtitle: 'Вместе сделаем продукты экосистемы лучшими на рынке!',
                  description: '',
                  link: '/legacy/sign-in?target_uri=https://sbidea.ru/ecosystem/forward_to_ideas'
                }
              ],
              status: 200,
              statusText: 'OK',
              headers: null,
              config: {},
              request: {}
            })
          }
          ,1000)
      )
    )
  },

  /**
   * получить статистику платформы для отображения на главной
   */
  getStatisticsIndicators(): Promise<AxiosResponse<Array<IStatisticsIndicator>>> {
    /**
     * заглушка пока бэк не готов
     */
    return new Promise<AxiosResponse<Array<IStatisticsIndicator>>>(
      (resolve) => (
        setTimeout(
          () => {
            resolve({
              data: [
                {
                  id: 'comments',
                  value: 4990
                },
                {
                  id: 'ideas',
                  value: 2204
                },
                {
                  id: 'heroes',
                  value: 929
                }
              ],
              status: 200,
              statusText: 'OK',
              headers: null,
              config: {},
              request: {}
            })
          }
          ,500)
      )
    )
  }
}