// @ts-nocheck
import {AxiosResponse} from "axios";
import {ITagDTO} from "../../models/landings/constructor/ITemplatesDTO/ITagsDTO/ITagDTO";
import {ITemplatesDTO} from "../../models/landings/constructor/ITemplatesDTO";
import {ILandingsDTO} from "../../models/landings/constructor/ILandingsDTO";
import {ILandingCard} from "../../models/landings/constructor/ILandingsDTO/ILandingCard";
import {instance} from "../index";

interface ILandingConstructorApi {

}

export const landingsConstructorApi = {


	// ненастоящая апишка
	getTabs() {
		return new Promise<AxiosResponse>(
			(resolve) =>
				    resolve({
				      data: {
				        tabs: [
									{
										name: 'Все страницы',
										id: 'all',
									},
									{
										name: 'Опубликованные',
										id: 'public',
									},
									{
										name: 'Черновики',
										id: 'drafts',
									},
									{
										name: 'По доступу',
										id: 'access',
									}
								]
				      },
				      status: 200,
				      statusText: 'OK',
				      headers: null,
				      config: {},
				      request: {}
				      })
				  )
	},

	getLandings(tab:string) {
		return new Promise<AxiosResponse<ILandingsDTO>>(
			(resolve) => resolve({
				data: {
					content: [
						{
							id: '1',
							name: 'Название страницы',
							preview: 'https://sbidea.ru/attachments/1/c3/3aac07-2d8c-4752-8380-77d4dfba73a7/landingPreview.png',
							views: 123,
							requests: 1234,
							conversion: 99,
							published: true,
							capabilities: ['', '']
						},
						{
							id: '2',
							name: 'Очень длинное название страницы, чтобы мы могли полностью ощутить как должен работать перенос названия 2',
							preview: 'https://sbidea.ru/attachments/1/c3/3aac07-2d8c-4752-8380-77d4dfba73a7/landingPreview.png',
							views: 123,
							requests: 1234,
							conversion: 99,
							published: true,
							capabilities: ['', '']
						},
						{
							id: '3',
							name: 'Очень длинное название страницы, чтобы мы могли полностью ощутить как должен работать перенос названия 2Очень длинное название страницы, чтобы мы могли полностью ощутить как должен работать перенос названия 2',
							preview: 'https://sbidea.ru/attachments/1/c3/3aac07-2d8c-4752-8380-77d4dfba73a7/landingPreview.png',
							views: 123,
							requests: 1234,
							conversion: 99,
							published: true,
							capabilities: ['', '']
						},
						{
							id: '4',
							name: 'Название страницы 4',
							preview: 'https://sbidea.ru/attachments/1/c3/3aac07-2d8c-4752-8380-77d4dfba73a7/landingPreview.png',
							views: 123,
							requests: 1234,
							conversion: 99,
							published: true,
							capabilities: ['', '']
						},
						{
							id: '5',
							name: 'Название страницы 5',
							preview: 'https://sbidea.ru/attachments/1/c3/3aac07-2d8c-4752-8380-77d4dfba73a7/landingPreview.png',
							views: 123,
							requests: 1234,
							conversion: 99,
							published: true,
							capabilities: ['', '']
						},
					]
				},
				status: 200,
				statusText: 'OK',
				headers: null,
				config: {},
				request: {}
				})
		)
	},

	getLanding(id:string) {
		return new Promise<AxiosResponse<ILandingCard>>(
			(resolve) => resolve({
				data: {
							id: '1',
							name: 'Очень длинное название страницы, чтобы мы могли полностью ощутить как должен работать перенос названия 2',
							preview: 'https://sbidea.ru/attachments/1/c3/3aac07-2d8c-4752-8380-77d4dfba73a7/landingPreview.png',
							views: 123,
							requests: 1234,
							conversion: 99,
							published: true,
							capabilities: ['', ''],
							versions: [{
								id: '123',
								name: "version 1",
								status: "ACTIVE",
								dateOfCreation: new Date(),
							},
								{
									id: '1234',
									name: "version 2",
									status: "NOT_ACTIVE",
									dateOfCreation: new Date(),
								},
								{
									id: '12345',
									name: "version 3",
									status: "NOT_ACTIVE",
									dateOfCreation: new Date(),
								}
								]
				},
				status: 200,
				statusText: 'OK',
				headers: null,
				config: {},
				request: {}
			})
		)
	},

	getTemplatesTags() {
		return new Promise<AxiosResponse<Array<ITagDTO>>> (
			(resolve) => resolve( {
				data: [
					{
						id: 'Все',
						name: 'Все'
					},
					{
						id: 'Праздник',
							name: 'Праздник'
					},
					{
						id: 'Презентация',
							name: 'Презентация'
					},
					{
						id: 'Многозначное',
							name: 'Многозначное'
					},
					{
						id: 'Однозначное',
							name: 'Однозначное'
					},
					{
						id: 'Развлечения',
							name: 'Развлечения'
					}
					],

				status: 200,
				statusText: 'OK',
				headers: null,
				config: {},
				request: {}
			})
		)
	},

	getTemplates(activeTag: string) {
		// @ts-ignore
		// @ts-ignore
		// @ts-ignore
		return new Promise<AxiosResponse<ITemplatesDTO>>(
			(resolve) => resolve({
				data: {
					content: [
						{
							id: '1',
							name: 'Универсальный шаблон',
							description: 'Универсальная страница, подходит для больших текстов и списков',
							preview: 'https://sbidea.ru/attachments/1/c3/3aac07-2d8c-4752-8380-77d4dfba73a7/landingPreview.png',
							tags: [
								{
									id: '1',
									name: 'Праздник'
								}
							]
						},
						{
							id: '2',
							name: 'Универсальный шаблон',
							description: 'Универсальная страница, подходит для больших текстов и списков',
							preview: 'https://sbidea.ru/attachments/1/c3/3aac07-2d8c-4752-8380-77d4dfba73a7/landingPreview.png',
							tags: [
								{
									id: '1',
									name: 'Праздник'
								}
							]
						},
						{
							id: '3',
							name: 'Универсальный шаблон',
							description: 'Универсальная страница, подходит для больших текстов и списков',
							preview: 'https://sbidea.ru/attachments/1/c3/3aac07-2d8c-4752-8380-77d4dfba73a7/landingPreview.png',
							tags: [
								{
									id: '2',
									name: 'Категория 2'
								}
							]
						},
						{
							id: '4',
							name: 'Универсальный шаблон',
							description: 'Универсальная страница, подходит для больших текстов и списков',
							preview: 'https://sbidea.ru/attachments/1/c3/3aac07-2d8c-4752-8380-77d4dfba73a7/landingPreview.png',
							tags: []
						},
						{
							id: '5',
							name: 'Универсальный шаблон',
							description: 'Универсальная страница, подходит для больших текстов и списков',
							preview: 'https://sbidea.ru/attachments/1/c3/3aac07-2d8c-4752-8380-77d4dfba73a7/landingPreview.png',
							tags: []
						}
					]
				},
				status: 200,
				statusText: 'OK',
				headers: null,
				config: {},
				request: {}
			})
		)
	}

}